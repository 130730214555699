import React, { useEffect, useContext, useState } from "react";
import Container from "../../components/common/Container";
import Banner from "../../components/common/Banner";
import { Link } from "react-router-dom";
import Allsportdata from "../../components/allsportdata/Allsportdata";
import exchangeBanner from "../../assets/images/Exchange_Page_top.png";
import sidebarCard1 from "../../assets/images/cas-1.png";
import sidebarCard2 from "../../assets/images/cas-2.png";
import sidebarCard3 from "../../assets/images/cas-3.png";

function Exchange() {
  const [matchdatas, setMatchData] = useState({});
  const [selectedTypes, setSelectedTypes] = useState("competitions");

  const fetchData = (selectedTypes) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/games/getExchangeData?type=All&filter=${selectedTypes}`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        setMatchData(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTypeChange = (event) => {
    setSelectedTypes(event.target.value);
    fetchData(event.target.value);
  };

  useEffect(() => {
    fetchData(selectedTypes);
  }, []);

  return (
    <>
      {matchdatas && (
        <Container size="full">
          <div className="content-w-sidebar exchange-page">
            <div className="left-content">
              <Banner image={exchangeBanner} />
              <div className="filter-btn-wrapper">
                <div className="filter-btn">
                  <span>
                    View by:
                    <select
                      className="sport-all-data"
                      name="filterby"
                      id="filterby"
                      value={selectedTypes}
                      onChange={handleTypeChange}
                    >
                      <option value="competitions" selected="">
                        Competitions
                      </option>
                      <option value="time">Time</option>
                    </select>
                  </span>
                </div>
              </div>
              <Allsportdata matchData={matchdatas} selectedTypes={selectedTypes}/>
            </div>
            <div className="right-content sidebar">
              <div className="sidebar-box">
                <div className="title">All Casino</div>
                <div className="sidebar-card">
                  <Link to="/">
                    {" "}
                    <img src={sidebarCard1} />
                  </Link>
                  <Link to="/">
                    {" "}
                    <img src={sidebarCard2} />
                  </Link>
                  <Link to="/">
                    {" "}
                    <img src={sidebarCard3} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default Exchange;
