import React, { useEffect, useState } from 'react'
import Container from '../../components/common/Container'
import Banner from '../../components/common/Banner'
import { Link } from 'react-router-dom'
import Allsportdata from '../../components/allsportdata/Allsportdata'
import inPlaybanner from '../../assets/images/in_Playe_Page_Top.png'
import sidebarCard1 from '../../assets/images/cas-1.png'
import sidebarCard2 from '../../assets/images/cas-2.png'
import sidebarCard3 from '../../assets/images/cas-3.png'

function Inplay() {

  const [matchdata, setMatchData] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState("competitions");

    const fetchData = async (selectedTypes) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/games/getInplayData?type=All`);
            const data = await response.json();
            setMatchData(data);
        } catch (error) {
            console.error('Error fetching API data:', error);
        }
    };

    const handleTypeChange = (event) => {
        setSelectedTypes(event.target.value);
        fetchData(event.target.value);
      };

    useEffect(() => {
        fetchData(selectedTypes);
    }, []);

    console.log('inplay match', matchdata)

  return (
    <Container size='full'>
    <div className='content-w-sidebar'>
    <div className='left-content'>
        <Banner image={inPlaybanner}/>
        { <div className='filter-btn-wrapper'>
            <div className="filter-btn">
                <span>View by:
                        <select className='sport-all-data' name="filterby" id="filterby" value={selectedTypes} onChange={handleTypeChange}>
                            <option value="competitions" selected="">Competitions</option>
                            <option value="time">Time</option>
                        </select>
                    </span>
            </div>
        </div>}
        <Allsportdata matchData={matchdata}  selectedTypes={selectedTypes} />
    </div>
    <div className='right-content sidebar'>
        <div className='sidebar-box'>
        <div className='title'>All Casino</div>
        <div className='sidebar-card'>
            <Link to='/'> <img src={sidebarCard1}/></Link>
            <Link to='/'> <img src={sidebarCard2}/></Link>
            <Link to='/'> <img src={sidebarCard3}/></Link>
        </div>
        </div>
    </div>
    </div>
</Container>
  )
}

export default Inplay